<template>
  <section class="my-5 partners">
    <b-container>
      <h2 class="my-5 font-bold">{{ $t("title.our_companies") }}</h2>
    </b-container>
    <swiper :options="swiperOption">
      <swiper-slide
        class="text-center mx-auto"
        v-for="slide in slides"
        :key="slide.index"
      >
        <CompanyDetails :slide="slide" />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev">
        <span><i class="icon-forward"></i></span>
      </div>
      <div class="swiper-button-next" slot="button-next">
        <span><i class="icon-back"></i></span>
      </div>
    </swiper>
  </section>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";

export default {
  name: "OurPartners",
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 50,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          1440: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 10
          }
        }
      },
      slides: []
    };
  },
  mounted() {
    this.handleGetCompanies();
  },
  components: {
    CompanyDetails: () => import("@/components/Home/OurCompanies/SingleCompany")
  },
  methods: {
    async handleGetCompanies() {
      try {
        const companies = await IndexData({ reqName: "companies" });
        this.slides = companies.data.companies;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
